<template>
	<div>
		<b-modal
			centered
			id="ModalCreateEditUser"
			size="lg"
			modal-class="custom-dialog dialog-600"
			:title="row ? 'Editar' : 'Nuevo miembro'"
			no-close-on-esc
			no-close-on-backdrop
			hide-footer
			@close="close"
		>
			<form-render ref="formRenderCreateEditUser" :key="keyCreateEditUser" :fields="fields" :form.sync="form"> </form-render>
			<b-button variant="warning" class="mt-2 mb-2" @click="sendForm" :disabled="loading.createUpdate">
				<span v-if="loading.createUpdate" class="mt-0 pl-2 pr-2"><b-spinner small /> </span>
				<span v-if="!loading.createUpdate" class="mb-1">Confirmar</span>
			</b-button>
		</b-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services'

export default {
  name: 'ModalCreateEditUser',
  props: ['getList', 'row', 'mySession'],
  data() {
    return {
      baseService: new BaseServices(this),
      keyCreateEditUser: 0,
      form: {},
      fields: [],
      loading: {
        createUpdate: false
      }
    }
  },
  computed: {
    ...mapGetters({
      globalData: 'getGlobalData'
    })
  },
  watch: {
    globalData: {
      handler() {
        this.setCountries()
      }
    },
    row: {
      handler() {
        if (!!this.row) {
          const indexFieldActivated = this.fields.findIndex(field => field.name === 'activated')
          this.row.activated = this.row.activated ? { text: 'Activo', id: 'active' } : { text: 'Inactivo', id: 'inactive' }
          this.row.countries = this.globalData.countries.filter(country => country.id === this.row.country_id)[0]
          this.form = this.row
          if (indexFieldActivated === -1) this.fields.push({ fieldType: 'FieldRadio', name: 'activated', label: 'Estado', align: 'h', containerClass: 'col-12 container-info', options: [{ text: 'Activo', id: 'active' }, { text: 'Inactivo', id: 'inactive' }] })
        } else {
          this.form = {}
          this.fields = this.fields.filter(field => field.name !== 'activated')
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldInput', name:'first_name', label: 'Nombres', containerClass: 'col-12 container-info', clearable: true },
        { fieldType: 'FieldInput', name:'last_name', label: 'Apellido', containerClass: 'col-12 container-info', clearable: true },
        { fieldType: 'FieldInput', name:'email', label: 'Correo', containerClass: 'col-12 container-info', clearable: true },
        { fieldType: 'FieldSelect', useLabel: true, name: 'countries', label: 'País', validation: 'required', containerClass: 'col-12 container-info', addFlags: true, sort: true}
      ]
      this.setCountries()
    },
    setCountries() {
      const indexFieldCountries = this.fields.findIndex(field => field.name === 'countries')
      if (indexFieldCountries !== -1 && this.globalData.countries?.length) {
        this.fields[indexFieldCountries].options = this.globalData.countries
      }
    },
    sendForm() {
      let name = 'postTeamsUsers'
      let title = '¡Usuario creado!'
      let msg = 'El usuario ha sido creado con éxito.'
      let data = {}
      this.loading.createUpdate = true
      if (!!this.row) data = { ...this.row }
      data.first_name = this.form.first_name
      data.last_name = this.form.last_name
      data.email = this.form.email
      data.country_id = this.form.countries.id
      data.admin_user_id = 4578
      data.admin_user_email = this.mySession.email
      if (!!this.row) data.activated = this.form.activated.id === 'active' ? 1 : 0
      const params = {}
      if (!!this.row) {
        name = 'putTeamsUsers'
        params.user_id = this.row.id
        title = '¡Usuario editado!'
        msg = 'El usuario ha sido editado con éxito.'
      }
      this.baseService.callService(name, data, params)
        .then(() => {
          this.$newSuccess(title, msg)
          this.$bvModal.hide('ModalCreateEditUser')
          this.$emit('getList')
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-guardar', {code: err}))
        })
        .finally(() => {
          this.loading.createUpdate = false
        })
    },
    close() {
      this.$bvModal.hide('ModalCreateEditUser')
    }
  }
}
</script>
