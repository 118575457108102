<template>
	<div>
		<b-card>
			<div class="d-inline-block w-100 p-2" v-if="rows && !loading.users">
				<b-button class="float-right" variant="warning" @click="openModal(null)"><feather-icon icon="UserPlusIcon" size="16" /> Nuevo miembro</b-button>
			</div>
			<div class="p-2" v-if="rows && !loading.users">
				<users-list :rows="rows" @openModal="openModal" @getList="setInitialData" :mySession="mySession"></users-list>
			</div>
			<div v-else>
        <b-skeleton height="40px" width="20%" class="mt-2 mb-2 float-right"/>
				<b-skeleton-table :rows="15" :columns="5" :table-props="{ }" />
			</div>
			<ModalCreateEditUser :row="row" ref="ModalCreateEditUser" @getList="setInitialData" :mySession="mySession"></ModalCreateEditUser>
		</b-card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import UsersList from './components/UsersList.vue'
import ModalCreateEditUser from './components/ModalCreateEditUser.vue'

export default {
  components: { UsersList, ModalCreateEditUser },
  data() {
    return {
      rows: null,
      row: null,
      isEdit: false,
      loading: {
        users: true
      }
    }
  },
  computed: {
    ...mapGetters({
      users: 'getTeamsUsers',
      mySession: 'getSession',
      globalData: 'getGlobalData',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    users: {
      handler() {
        this.rows = this.users.rows?.sort(this.sortByUpdate)
      },
      immediate: true
    },
    mySession() {
      this.setInitialData()
    },
    generalLoading: {
      handler() {
        this.loading.users = !!this.generalLoading.getTeamsUsers
      }
    }
  },
  methods: {
    setInitialData() {
      this.$store.dispatch('fetchService', { name: 'getTeamsUsers' })
    },
    sortByUpdate(a, b) {
      const updateA = new Date(a.updated_at)
      const updateB = new Date(b.updated_at)
      return updateB - updateA
    },
    openModal(row) {
      this.row = row
      this.$bvModal.show('ModalCreateEditUser')
    }
  }
}
</script>
