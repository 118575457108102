<template>
	<b-table id="tableUsersTeam" :items="rows" :fields="schema">
		<template v-slot:cell(actionsRows)="row">
			<b-button variant="none" @click="editUser(row)">
				<feather-icon icon="Edit2Icon" size="14" class="text-primary" v-b-tooltip.hover title="Editar" />
			</b-button>
			<b-button variant="none" @click="confirmDeleteUser(row)">
				<feather-icon v-if="selected !== row.item.id" icon="TrashIcon" size="14" class="text-danger" v-b-tooltip.hover title="Eliminar" />
        <feather-icon v-if="loading.delete && selected === row.item.id" icon="LoaderIcon" class="spinner"/>
			</b-button>
		</template>
	</b-table>
</template>
<script>
import BaseServices from '@/store/services'

export default {
  props: ['rows', 'openModal', 'getList', 'mySession'],
  data() {
    return {
      baseService: new BaseServices(this),
      schema: [],
      selected: null,
      loading: {
        delete: false
      }
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.schema = [
        { key: 'first_name', label: 'Nombre', sortable: true },
        { key: 'last_name', label: 'Apellido', sortable: true },
        { key: 'email', label: 'Correo', sortable: true },
        { key: 'actionsRows', label: 'Acción', useSlot: true, sortable: false, class: 'text-center'}
      ]
    },
    getdata(val) {
      return {
        ...val.item,
        first_name: val.item.first_name,
        last_name: val.item.last_name,
        email: val.item.email,
        activated: val.item.activated,
        id: val.item.id,
        country_id: val.item.country_id
      }
    },
    editUser(val) {
      this.$emit('openModal', (true, this.getdata(val)))
    },
    confirmDeleteUser(val) {
      this.$yesno(this.$t('msg-pregunta-eliminar-usuario'), () => this.deleteUser(val)) 
    },
    deleteUser(val) {
      this.selected = val.item.id
      this.loading.delete = true
      const params = { user_id: val.item.id }
      const data = {
        user_id: val.item.id,
        email: val.item.email,
        admin_user_id: 4578,
        admin_user_email: this.mySession.email
      }
      this.baseService.callService('deleteTeamsUsersId', data, params)
        .then(() => {
          this.$newSuccess('Usuario eliminado Ok')
          this.$emit('getList')
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-eliminar-elemento', {code: err}))
        })
        .finally(() => {
          this.selected = null
          this.loading.delete = false
        })
    }
  }
}
</script>
